import React from 'react';
//import './doradoContent.css'; // Importa el archivo CSS aquí
import LocationHeader from "@templates/locations/components/LocationDorado/LocationHeader";

const DoradoContent = ({ page, indexPage, organizationName }) => {
   console.log(' Page:', page);
  return (
    <div className="location-show page-fade-in">
      <LocationHeader
          location= {page}
          pageTitle={page.title}
          breadcrumbLink2={{
            url: indexPage.uri,
            title: indexPage.acfPageMeta.metaTitle,
          }}
          image={page.acf.locationImage}
          organizationName={organizationName}
        />      
    </div>
  );
};

export default DoradoContent;
