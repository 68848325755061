import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import gql from "graphql-tag"
import withPreview from "@components/withPreview"

import Layout from "@components/layout"
import SEO from "@components/seo"
import SimpleBreadcrumbHero from "@components/common/SimpleBreadcrumbHero"
import LocationDetailSection from "@templates/locations/components/LocationDetailSection"
import LocationDepartmentSection from "./components/LocationDepartmentSection"
import LocationDepartmentSectionDorado from "./components/LocationDepartmentSectionDorado"
import DoradoContent from './components/LocationDorado/doradoContent';

const LocationShow = props => {
  const page =
    !!props.preview && !!props.preview.location
      ? props.preview.location.preview.node
      : props.data.allWpLocation.edges[0].node
  const indexPage = props.pageContext.pages.find(
    p => p.acfPageMeta.pageTemplate === "locations"
  )

  const directoryPage = props.pageContext.pages.find(
    p => p.acfPageMeta.pageTemplate === "directory"
  )

  return (
    <Layout>
      {page.slug !== 'dorado-2' ? (
        <div className="location-show page-fade-in">
        <SEO title={page.title} />
        <SimpleBreadcrumbHero
          pageTitle={page.title}
          breadcrumbLink2={{
            url: indexPage.uri,
            title: indexPage.acfPageMeta.metaTitle,
          }}
          image={page.acf.locationImage}
        />
        <LocationDetailSection
          organizationName={(page.acf.type || {}).description}
          location={page}
        />
        {(!!(page.acf.dcClinics && page.acf.dcClinics.length) || !!(page.acf.specializedClinics && page.acf.specializedClinics.length)) && (
          <LocationDepartmentSection
            directoryPageUri={directoryPage.uri}
            dcClinics={page.acf.dcClinics}
            specializedClinics={page.acf.specializedClinics}
            location={page}
          />
        )}
      </div>
      ) : (
        <>
          <div className="location-show page-fade-in">
            <SEO title={page.title} />                    
            <DoradoContent page={page} indexPage={indexPage}  organizationName={(page.acf.type || {}).description} />
             {(!!(page.acf.dcClinics && page.acf.dcClinics.length) || !!(page.acf.specializedClinics && page.acf.specializedClinics.length)) && (
          <LocationDepartmentSectionDorado
            directoryPageUri={directoryPage.uri}
            dcClinics={page.acf.dcClinics}
            specializedClinics={page.acf.specializedClinics}
            location={page}
          />
        )}
          </div>
        </>
      )}
    </Layout>
  )
}

LocationShow.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.shape({
    pages: PropTypes.array,
    directoryUri: PropTypes.string,
  }),
}
LocationShow.defaultProps = {
  pageContext: {
    pages: [],
  },
}

export const query = graphql`
  query($slug: String) {
    allWpLocation(filter: { slug: { eq: $slug, ne: null } }) {
      edges {
        node {
          id
          title
          uri
          slug
          acf {
            about
            city
            locationImage {
              altText
              sourceUrl
            }
            map {
              placeId
              longitude
              latitude
            }
            hours {
              hoursTitle
              specificHours {
                title
                hoursText
              }
            }
            stateAbbreviation
            state
            streetAddress
            email
            telephone
            otherTelephones {
              telephoneLabel
              telephoneNumber
            }
            zipCode
            type {
              id
              name
              description
              slug
            }
            dcClinics {
              department {
                id
                name
              }
              sublocation {
                ... on WpLocation {
                  id
                  title
                  acf {
                    publishFinal
                  }
                }
              }
              description
              leaders {
                medicalStaff {
                  ... on WpDoctor {
                    id
                    uri
                    title
                    acf {
                      publishFinal
                      title
                      image {
                        altText
                        sourceUrl
                      }
                    }
                  }
                }
                leaderTitle
              }
            }
            specializedClinics {
              department {
                id
                name
              }
              sublocation {
                ... on WpLocation {
                  id
                  title
                  acf {
                    publishFinal
                  }
                }
              }
              description
              leaders {
                medicalStaff {
                  ... on WpDoctor {
                    id
                    uri
                    title
                    acf {
                      publishFinal
                      title
                       image {
                        altText
                        sourceUrl
                      }
                    }
                  }
                }
                leaderTitle
              }
            }
          }
        }
      }
    }
  }
`

const PREVIEW_QUERY = gql`
  query PreviewLocationShowQuery($id: ID!) {
    location(id: $id, idType: DATABASE_ID) {
      preview {
        node {
          title
          uri
          slug
          acf {
            about
            city
            locationImage {
              altText
              sourceUrl
            }
            map {
              placeId
              longitude
              latitude
            }
            hours {
              hoursTitle
              specificHours {
                title
                hoursText
              }
            }
            stateAbbreviation
            state
            streetAddress
            email
            telephone
            otherTelephones {
              telephoneLabel
              telephoneNumber
            }
            zipCode
            type {
              id
              name
              description
              slug
            }
            dcClinics {
              department {
                id
                name
              }
              sublocation {
                ... on Location {
                  id
                  title
                  acf {
                    publishFinal
                  }
                }
              }
              description
              leaders {
                medicalStaff {
                  ... on Doctor {
                    id
                    uri
                    title
                    acf {
                      title
                    }
                  }
                }
                leaderTitle
              }
            }
            specializedClinics {
              department {
                id
                name
              }
              sublocation {
                ... on Location {
                  id
                  title
                  acf {
                    publishFinal
                  }
                }
              }
              description
              leaders {
                medicalStaff {
                  ... on Doctor {
                    id
                    uri
                    title
                    acf {
                      title
                    }
                  }
                }
                leaderTitle
              }
            }
          }
        }
      }
    }
  }
`

export default withPreview({ preview: PREVIEW_QUERY })(LocationShow)
