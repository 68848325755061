import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import "./index.css";

const LocationMultiSpecsDorado = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpLocation(filter: { slug: { eq: "dorado-2" } }) {
        nodes {
          acfMultiSpecs {
            specialties {
              specialists
              doctors {
                namedoctor
                medicalStaff {
                  ... on WpDoctor {
                    id
                    uri
                    title
                    acf {
                      publishFinal
                      title
                      image {
                        sourceUrl
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { specialties } = data.allWpLocation.nodes[0].acfMultiSpecs;

  const salto1 = 3;
  const salto2 = 10;

  const column1 = specialties.slice(0, salto1);
  const column2 = specialties.slice(salto1, salto2);
  const column3 = specialties.slice(salto2);

  const [expanded, setExpanded] = useState(false);

  const handleButtonClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="location-department-dropdown">
      <div className="location-department-dropdown__head">
        <h2 className="heading-text heading-text--lg heading-text--primary">Clínicas Multi-especializadas</h2>
        <button className={`brand-button btn btn-utility btn-tertiary btn-fill ${expanded ? 'location-department-dropdown__icon--close' : ''}`} onClick={handleButtonClick}>
          <span className="brand-button__icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus">
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </span>
        </button>
      </div>
      {expanded && (
        <div className="location-department-dropdown__content multiespec row">
          <div className="col-md-4 col-12 colummulti">
            {column1.map((specialty, index) => (
              <div key={index}>
                <h4>{specialty.specialists}</h4>
                {specialty.doctors.map((doctor, doctorIndex) => (
                  <div key={doctorIndex} className="cbox row">
                    <a
                        href={doctor.medicalStaff && doctor.medicalStaff.uri ? doctor.medicalStaff.uri : '#'}
                        target="_blank"
                        style={{ pointerEvents: (doctor.medicalStaff && doctor.medicalStaff.uri) ? 'auto' : 'none' }}
                      >
                    <div className="col-md-3 col-3 cboximg">
                       <img
                          src={doctor.medicalStaff && doctor.medicalStaff.acf.image ? doctor.medicalStaff.acf.image.sourceUrl : "/images/img-generica.jpg"}
                          alt="img doc"
                        />
                    </div>
                    <div className="col-md-9 col-9">
                      <p className="pbold">{doctor.namedoctor}</p>
                    </div>
                    </a>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="col-md-4 col-12 colummulti">
            {column2.map((specialty, index) => (
              <div key={index}>
                <h4>{specialty.specialists}</h4>
                {specialty.doctors.map((doctor, doctorIndex) => (
                  <div key={doctorIndex} className="cbox row">
                    <a
                        href={doctor.medicalStaff && doctor.medicalStaff.uri ? doctor.medicalStaff.uri : '#'}
                        target="_blank"
                        style={{ pointerEvents: (doctor.medicalStaff && doctor.medicalStaff.uri) ? 'auto' : 'none' }}
                      >
                    <div className="col-md-3 col-3 cboximg">
                       <img
                          src={doctor.medicalStaff && doctor.medicalStaff.acf.image ? doctor.medicalStaff.acf.image.sourceUrl : "/images/img-generica.jpg"}
                          alt="img doc"
                        />
                    </div>
                    <div className="col-md-9 col-9">
                      <p className="pbold">{doctor.namedoctor}</p>
                    </div>
                    </a>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="col-md-4 col-12 colummulti">
            {column3.map((specialty, index) => (
              <div key={index}>
                <h4>{specialty.specialists}</h4>
                {specialty.doctors.map((doctor, doctorIndex) => (
                  <div key={doctorIndex} className="cbox row">
                    <a
                        href={doctor.medicalStaff && doctor.medicalStaff.uri ? doctor.medicalStaff.uri : '#'}
                        target="_blank"
                        style={{ pointerEvents: (doctor.medicalStaff && doctor.medicalStaff.uri) ? 'auto' : 'none' }}
                      >
                    <div className="col-md-3 col-3 cboximg">
                       <img
                          src={doctor.medicalStaff && doctor.medicalStaff.acf.image ? doctor.medicalStaff.acf.image.sourceUrl : "/images/img-generica.jpg"}
                          alt="img doc"
                        />
                    </div>
                    <div className="col-md-9 col-9">
                      <p className="pbold">{doctor.namedoctor}</p>
                    </div>
                    </a>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationMultiSpecsDorado;
