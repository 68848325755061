import React from "react"
import PropTypes from "prop-types"

import BodyText from "@components/common/BodyText"
import IconLinkButton from "@components/common/IconLinkButton"
import { ReactComponent as ChevronRight } from "@images/icons/chevron-right.svg"

import language from "@language"

import "./style.scss"

const LocationDepartmentStaffCardDorado = props => {
  const medicalStaff = props.departmentLeader.medicalStaff || {}
 const sanitizeUri = uri => {
  // Eliminar la subcadena "/directorio/" y los guiones
  let sanitizedUri = uri.replace(/\/directorio\//g, '').replace(/-/g, '');
  // Eliminar el '/' del final si está presente
  sanitizedUri = sanitizedUri.replace(/\/$/, '');
  return sanitizedUri;
};


  // URI sanitizada
  const sanitizedUri = sanitizeUri(medicalStaff.uri);
console.log("ENTROOO ");
      console.log(medicalStaff.acf);
      
  return (
    
    <div className="location-department-staff-card staffdorado">
      <a href={medicalStaff.uri} target="_blank">
      <img src="/images/ico-estetoscopio.png" alt="ico-estetoscopio" className="ico-estetoscopio" />      
      <img
        src={medicalStaff.acf.image ? medicalStaff.acf.image.sourceUrl : "/images/img-generica.jpg"}
        alt="imgdefault"
        className="imgdoctogenerica"
      />

      <BodyText
        className="location-department-staff-card__name"
        text={`${medicalStaff.title}, ${medicalStaff.acf.title}`}
        color="primary"
        size="md2"
      />
      <BodyText
        className="location-department-staff-card__department"
        text={props.title}
        size="sm"
        color="dark-gray"
      />
     {/* <BodyText
  className="location-department-staff-card__title"
  text={props.departmentLeader.leaderTitle}
  size="sm"
  color="dark-gray"
/> */}
      {/* 
       <IconLinkButton
        icon={<ChevronRight />}
        className={`location-department-staff-card__cta staffcarddorado ${sanitizedUri}`}        
        uri={medicalStaff.uri}
        text={language[props.language].view_their_profile}
      />
      */}
      </a>
    
    </div>
  )
}

LocationDepartmentStaffCardDorado.propTypes = {
  departmentLeader: PropTypes.shape({
    leaderTitle: PropTypes.string,
    medicalStaff: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
  }),
  language: PropTypes.string,
  title: PropTypes.string,
}
LocationDepartmentStaffCardDorado.defaultProps = {
  language: "es",
}

export default LocationDepartmentStaffCardDorado
