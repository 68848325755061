import React from "react"
import PropTypes from "prop-types"
import LocationDepartmentDropdownDorado from "../LocationDepartmentDropdownDorado"
import LocationMultiSpecsDorado from "../LocationMultiSpecsDorado"
import HeadingText from "@components/common/HeadingText"
import language from "@language"

import "./style.scss"

const LocationDepartmentSectionDorado = props => {
  return (
    <div className="doradosection location-department-section">
    
      <div className="page-wrap">
        {
          !!props.specializedClinics && // if props.specializedClinics not undefined
            <div className="mb-60px">
              <HeadingText
                className="location-department-section__title"
                size="md"
                color="dark"
                text={language[props.language].specialized_centers}
              />
              {props.specializedClinics.map((locationDepartment, index) => {
                return (
                  <LocationDepartmentDropdownDorado
                    key={`department--${index}`}
                    directoryPageUri={props.directoryPageUri}
                    description={locationDepartment.description}
                    department={locationDepartment.department}
                    subLocation={locationDepartment.sublocation}
                    leaders={(locationDepartment.leaders || []).filter(
                      l => l.medicalStaff && l.medicalStaff.acf.publishFinal
                    )}
                    location={props.location}
                  />
                )
              })}
            </div>
        }
        {
          !!props.dcClinics &&  // if props.dcClinics not undefined
            <div className="mb-100px dcentersclinic">
              <HeadingText
                className="location-department-section__title"
                size="md"
                color="dark"
                text={language[props.language].doctors_center_clinics}
              />
              {props.dcClinics.map((locationDepartment, index) => {
                return (
                  <LocationDepartmentDropdownDorado
                    key={`department--${index}`}
                    directoryPageUri={props.directoryPageUri}
                    description={locationDepartment.description}
                    department={locationDepartment.department}
                    subLocation={locationDepartment.sublocation}
                    leaders={(locationDepartment.leaders || []).filter(
                      l => l.medicalStaff && l.medicalStaff.acf.publishFinal
                    )}
                    location={props.location}
                  />
                )
              })}
              <LocationMultiSpecsDorado/>


            </div>
        }

      </div>
    </div>
  )
}

LocationDepartmentSectionDorado.propTypes = {
  directoryPageUri: PropTypes.string,
  language: PropTypes.string,
  dcClinics: PropTypes.array,
  specializedClinics: PropTypes.array,
  location: PropTypes.shape({
    id: PropTypes.string,
  }),
}
LocationDepartmentSectionDorado.defaultProps = {
  language: "es",
}

export default LocationDepartmentSectionDorado
