import React from "react"
import PropTypes from "prop-types"

import UniversalLink from "@components/common/UniversalLink"
import BrandButton from "@components/common/BrandButton"

import classnames from "classnames"

import "./style.scss"

const IconLinkButton = props => {
  return (
    <UniversalLink
      className={classnames("icon-link-button", props.className)}
      href={props.uri}
      onClick={props.onClick}
    >
      {props.iconSide === "left" && (
        <BrandButton
          className={classnames("icon-link-button__button", {
            "icon-link-button__icon--left": props.text,
          })}
          btnStyle={props.btnStyle}
          icon={props.icon}
          size={props.size}
          color="primary"
          element="span"
        />
      )}
      {props.text && (
        <span
          className={`${props.textColor}-text icon-link-button__text icon-link-button__text--${props.textSize}`}
        >
          &nbsp;{props.text}
        </span>
      )}
      {props.iconSide === "right" && (
        <BrandButton
          className={classnames("icon-link-button__button", {
            "icon-link-button__icon--right": props.text,
          })}
          btnStyle={props.btnStyle}
          icon={props.icon}
          size={props.size}
          color="primary"
          element="span"
        />
      )}
    </UniversalLink>
  )
}

IconLinkButton.propTypes = {
  btnStyle: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.object,
  uri: PropTypes.string,
  textSize: PropTypes.oneOf(["md", "xs"]),
  size: PropTypes.string,
  text: PropTypes.string,
  iconSide: PropTypes.oneOf(["left", "right"]),
  textColor: PropTypes.string,
  onClick: PropTypes.func,
}
IconLinkButton.defaultProps = {
  btnStyle: "outline",
  iconSide: "left",
  size: "utility",
  textSize: "md",
  textColor: "primary-dark",
}

export default IconLinkButton
