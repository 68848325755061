import React, { useState } from "react"
import PropTypes from "prop-types"
import { ReactComponent as PlusIcon } from "@images/icons/plus.svg"
import classnames from "classnames"

import BrandButton from "@components/common/BrandButton"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import language from "@language"
import * as c from "@utilities/constants"
import PageCarousel from "@components/PageCarousel"
import { useWindowSize } from "@utilities/componentUtils"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import "./style.scss"
import LocationDepartmentStaffCard from "../LocationDepartmentStaffCard"

const LocationDepartmentDropdown = props => {
  const [isOpen, setOpen] = useState(false)

  const { width } = useWindowSize()

  const department = props.department
  const subLocation = props.subLocation

  const title = (department || {}).name || (subLocation || {}).title

  let directoryHref = ""
  if (department) {
    directoryHref = `${props.directoryPageUri}?${c.DEPARTMENT_PARAM_KEY}=${department.id}&${c.LOCATION_PARAM_KEY}=${props.location.id}`
  } else if (subLocation) {
    directoryHref = `${props.directoryPageUri}?${c.LOCATION_PARAM_KEY}=${props.location.id}` // filter by primary (hospital) location 
    console.log(props)
  }

  if (subLocation && !subLocation.acf.publishFinal) {
    return null
  }

  return (
    <div className="location-department-dropdown">
      <div className="location-department-dropdown__head">
        <HeadingText size="lg" color="primary" text={title} />
        <BrandButton
          className={classnames({
            "location-department-dropdown__icon--close": isOpen,
          })}
          color="tertiary"
          icon={<PlusIcon />}
          size="utility"
          ariaLabel="Expand"
          onClick={isOpen ? () => setOpen(false) : () => setOpen(true)}
        />
      </div>
      {isOpen && (
        <div className="location-department-dropdown__content">
          <div className="location-department-dropdown__left">
            <PageCarousel
              align="start"
              contentSlide={
                <div className="location-department-dropdown__content-slide">
                  <BodyText
                    className="location-department-dropdown__description"
                    size="md2"
                    text={props.description}
                  />
                  <AnchorLink
                    to={directoryHref + "#dt"}
                    title="Todo el personal"
                    className="location-department-dropdown__cta--desktop brand-button btn btn-md btn-secondary btn-fill"
                  />
                </div>
              }
              disableControls={width >= 768}
              slides={
                !!props.leaders &&
                props.leaders.slice(0, 3).map(departmentLeader => {
                  return (
                    <LocationDepartmentStaffCard
                      title={title}
                      departmentLeader={departmentLeader}
                    />
                  )
                })
              }
            />
            <BrandButton
              color="secondary"
              className="location-department-dropdown__cta--mobile"
              text={language[props.language].all_staff}
              href={directoryHref}
              ariaLabel="Expand"
            />
          </div>
        </div>
      )}
    </div>
  )
}

LocationDepartmentDropdown.propTypes = {
  description: PropTypes.string,
  directoryUri: PropTypes.string,
  language: PropTypes.string,
  subLocation: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    acf: PropTypes.shape({
      publishFinal: PropTypes.bool,
    }),
  }),
  department: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  leaders: PropTypes.array,
  location: PropTypes.shape({
    id: PropTypes.string,
  }),
}

LocationDepartmentDropdown.defaultProps = {
  language: "es",
  department: {
    id: PropTypes.string,
    name: PropTypes.string,
  },
  leaders: [],
}

export default LocationDepartmentDropdown
