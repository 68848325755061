import React from 'react';
//import './doradoContent.css'; // Importa el archivo CSS aquí
import "./style.css"
import BreadcrumbText from "@components/common/BreadcrumbText"
import BodyText from "@components/common/BodyText"

import PropTypes from "prop-types"
import LocationMap from "@components/common/LocationMap"
import language from "@language"
import CopyLinkButton from "@components/common/CopyLinkButton"

const LocationHeader = props => {
  const defaultLanguage = language || "es";
   
  return (
    <>
      <div className="page-wrap">
        <div className="breadcrumbdorado">
          <div className="simple-breadcrumb-hero__breadcrumbs">
            <BreadcrumbText
              link1={{ url: "/", title: "Comienzo"}}
              link2={props.breadcrumbLink2}
              pageTitle={props.pageTitle}
            />
          </div>
        </div>
        <div className="headerdorado row">
          <div className="location-detail-section__primary-left col-md-6 contimage p-0l">
            <img src={props.image.sourceUrl} alt={props.pageTitle} />
          </div>
          <div className="col-md-6 contdescript">                    
            <p className="body-text body-text--xxl body-text--primary-dark location-detail-section__primary-left-top titd ">{props.organizationName} </p>
            <p className="body-text body-text--xxl body-text--primary-dark location-detail-section__primary-left-top titd">| Orlando Health {props.pageTitle}</p>
            <p className="body-text body-text--md2 body-text--dark-gray location-detail-section__description descdorado">{props.location.acf.about}</p>
          </div>      
        </div>
        <div className="doradosection direction">
          <div className="txtcenter contit">
            <h2>Dirección</h2>
          </div>
          <div className="row equal-height">
            <div className="col-md-8 col-12 cmap">
              <LocationMap
                className="location-detail-section__map"
                center={{
                  lat: props.location.acf.map.latitude,
                  lng: props.location.acf.map.longitude,
                }}
                zoom={15}
                handleGoogleMapsLoaded={() => { }}
                locations={[props.location]}
              />
            </div>
            <div className="col-md-4 col-12 ctit cdoctor p-0">
              <img src="/images/dchlogo.png" alt="Doctor" className="doctordir"/>
              <div className="cbox row" id="cboxdoctor">
                <div className="col-md-3 col-3 p-0">
                  <img src="/images/ico-direccion.png" alt="ico-dirección" className="icodireccion"/>
                </div>
                <div className="col-md-9 col-9 cdir" id="cdir">
                  <h4>Dirección</h4>
                  <p>900 carretera 696</p>
                  <p>Dorado, Puerto Rico 00646</p>
                  <CopyLinkButton language={props.language} />
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Horarios */}
        <div className="doradosection">
          <div className="txtcenter contit">
            <h2>Horarios</h2>
            <h3>Lunes a Domingo </h3>
          </div>
          <div className="containerbox boxdir">
            {!!props.location.acf.hours && (
              <>
                {props.location.acf.hours.map((hours, index) => (
                  <div className="cbox row" key={hours.hoursTitle}>
                    <div className="col-md-3 col-3 cboximg p-0r">                                            
                      <img src={`/images/hor${index + 1}.png`} alt={hours.hoursTitle} />
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>{hours.hoursTitle}</h3>
                      {hours.specificHours && hours.specificHours.map(specificHours => (
                        <div key={specificHours.title}>
                          <p className="hourtit pbold">{specificHours.title}</p>
                          <p className="hourtext">{specificHours.hoursText.replace(/<[^>]+>/g, '')}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      {/* Teléfonos */}
      <div className="doradosection telsection">
         {props.location.acf.telephone && (
              <div className="txtcenter contit">
                <h2>Teléfonos</h2>
                <h3>Cuadro telefónico: <span className="txttel">{props.location.acf.telephone}</span> </h3>
            </div> 
              )}
        <div className="containerbox boxtel">

            {
                  !!(props.location.acf.otherTelephones || []).length &&
                  props.location.acf.otherTelephones.map((tel,index) => {
                    return <div className="cbox row">
                    <div className="col-md-3 col-4 cboximg p-0r">                        
                        <img src={`/images/tel${index + 1}.png`} alt={tel.telephoneLabel} />
                    </div>
                    <div className="col-md-9 col-8 pmtel">
                      <h4>{tel.telephoneLabel}:</h4>
                      <p className="pbold">{tel.telephoneNumber}</p>
                    </div>
                    </div>
                  })

                }
          
        </div>
      </div>
    </>
  );
};


LocationHeader.propTypes = {
  organizationName: PropTypes.string,
  language: PropTypes.string,
  page: PropTypes.shape({
    location: PropTypes.shape({
      title: PropTypes.string,
      acf: PropTypes.shape({
        about: PropTypes.string,
        officeHours: PropTypes.string,
        labHours: PropTypes.string,
        state: PropTypes.string,
        streetAddress: PropTypes.string,
        email: PropTypes.string,
        telephone: PropTypes.string,
        otherTelephones: PropTypes.array,
        zipCode: PropTypes.string,
        map: PropTypes.shape({
          placeId: PropTypes.string,
          latitude: PropTypes.number,
          longitude: PropTypes.number,
        }),
      }),
    }),
  }).isRequired,
};

LocationHeader.defaultProps = {
  language: "es",
}


export default LocationHeader;
