import React from "react"
import PropTypes from "prop-types"

import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import UniversalLink from "@components/common/UniversalLink"

import CopyLinkButton from "@components/common/CopyLinkButton"
import LocationMap from "@components/common/LocationMap"
import Markdown from "markdown-to-jsx"

import { preFilterMarkdown } from "@utilities/markdownUtils"

import language from "@language"

import "./style.scss"

const LocationDetailSection = props => {
  return (
    <div className="location-detail-section content-spacing--md">
      <div className="page-wrap">
        <div className="row">
          <div className="location-detail-section__primary-left col-md-6">
            <BodyText
              className="location-detail-section__primary-left-top"
              size="xxl"
              color="primary-dark"
              text={props.organizationName}
            />
            <BodyText
              className="location-detail-section__primary-left-bottom"
              size="xxl"
              color="primary"
              text={props.location.title}
            />
            <BodyText
              className="location-detail-section__description"
              size="md2"
              color="dark-gray"
              text={props.location.acf.about}
            />
            {!!props.location.acf.hours && (
              <div className="location-detail-section__hours-section">
                <HeadingText
                  className="location-detail-section__hours-title"
                  color="dark"
                  size="md"
                  text={language[props.language].schedule}
                />
                <div className="location-detail-section__hours-row">
                  {props.location.acf.hours.map(hours => {
                    return (
                      <div className="location-detail-section__hours">
                        <HeadingText
                          className="location-detail-section__hours-title"
                          color="primary"
                          size="sm"
                          text={hours.hoursTitle}
                        />
                        <div className="location-detail-section__specific-hours">
                          {hours.specificHours &&
                            hours.specificHours.map(specificHours => {
                              return (
                                <div className="location-detail-section__specific-hour">
                                  <BodyText
                                    className="location-detail-section__specific-hour--title"
                                    size="sm"
                                    color="dark-gray"
                                    text={specificHours.title}
                                  />

                                  <Markdown
                                    options={{
                                      forceBlock: true,
                                      overrides: {
                                        p: {
                                          component: BodyText,
                                          props: {
                                            color: "dark-gray",
                                            size: "sm",
                                          },
                                        },
                                        span: {
                                          component: BodyText,
                                          props: {
                                            color: "dark-gray",
                                            size: "sm",
                                          },
                                        },
                                      },
                                    }}
                                  >
                                    {preFilterMarkdown(specificHours.hoursText)}
                                  </Markdown>
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
            <div className="location-detail-section__telephones">
              <HeadingText
                className="location-detail-section__hours-title"
                color="dark"
                size="md"
                text={language[props.language].telephones}
              />
              {props.location.acf.telephone && (

                <div className="mb-30px">
                  <BodyText element="span" color="dark-gray" size="sm">
                    {language[props.language].main_telephone}{': '}
                  </BodyText>
                  <UniversalLink href={`tel:${((props.location.acf.telephone || "").match(/\d/g) || []).join("")}`} size="sm">
                    <BodyText element="span" color="primary" size="sm">
                      {props.location.acf.telephone}
                    </BodyText>
                  </UniversalLink>
                </div>
              )}
              <div className="location-detail-section__extensions">
                {
                  !!(props.location.acf.otherTelephones || []).length &&
                  props.location.acf.otherTelephones.map(tel => {
                    return <div className="location-detail-section__extension mb-10px">
                      <BodyText element="span" color="dark-gray" size="sm">
                        {tel.telephoneLabel}{': '}
                      </BodyText>
                      <BodyText element="span" color="dark-gray" size="sm">
                        {tel.telephoneNumber}
                      </BodyText>
                    </div>
                  })

                }
              </div>
            </div>
          </div>
          <div className="location-detail-section__map-section col-md-6">
            <LocationMap
              className="location-detail-section__map"
              center={{
                lat: props.location.acf.map.latitude,
                lng: props.location.acf.map.longitude,
              }}
              zoom={15}
              handleGoogleMapsLoaded={() => { }}
              locations={[props.location]}
            />
            <div className="location-detail-section__address-section">
              <div className="location-detail-section__address-column">
                <div className="location-detail-section__address mb-30px">
                  <BodyText
                    className="mb-10px"
                    size="md"
                    color="primary"
                    fontWeight="bold"
                    text={language[props.language].address}
                  />
                  <BodyText color="dark-gray" size="sm">
                    {props.location.acf.streetAddress}
                  </BodyText>
                  <BodyText color="dark-gray" size="sm">
                    {`${props.location.acf.city}, ${props.location.acf.state} ${props.location.acf.zipCode}`}
                  </BodyText>
                </div>
                <div className="location-detail-section__contact">
                  {props.location.acf.email && (
                    <div className="mb-30px">
                      <BodyText
                        className="mb-10px"
                        size="md"
                        color="primary"
                        fontWeight="bold"
                        text={language[props.language].email}
                      />
                      <BodyText className="mb-10px" color="dark-gray" size="sm">
                        {props.location.acf.email}
                      </BodyText>
                    </div>
                  )}
                </div>
              </div>
              <div className="location-detail-section__links">                    
                {props.location.title == 'Santurce – Ciudadela' ? // If is specified location
                  <div class="download-app-card">
                    <BodyText className="mb-20px" size="md">
                      Descarga nuestra<br/>app de citas.
                    </BodyText>
                    <div>
                      <UniversalLink href="https://apps.apple.com/us/app/doctors/id1607392490">
                        <img src="/images/Download_on_the_App_Store_Badge_ES_RGB_blk_100217.svg" width="100%" alt="Download from the iOS Appstore" />
                      </UniversalLink>
                    </div>
                    <div Style="margin-top: 16px;">
                      <UniversalLink href="https://play.google.com/store/apps/details?id=com.doctorscenterhospital.patient">
                        <img src="/images/google-play-badge.png" width="100%" alt="Download from Google Play" />
                      </UniversalLink>
                    </div>
                  </div>
                  : ''
                }

                <CopyLinkButton language={props.language} />

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

LocationDetailSection.propTypes = {
  organizationName: PropTypes.string,
  language: PropTypes.string,
  location: PropTypes.shape({
    title: PropTypes.string,
    acf: PropTypes.shape({
      about: PropTypes.string,
      officeHours: PropTypes.string,
      labHours: PropTypes.string,
      state: PropTypes.string,
      streetAddress: PropTypes.string,
      email: PropTypes.string,
      telephone: PropTypes.string,
      otherTelephones: PropTypes.array,
      zipCode: PropTypes.string,
      map: PropTypes.shape({
        placeId: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
    }),
  }),
}
LocationDetailSection.defaultProps = {
  language: "es",
}

export default LocationDetailSection
