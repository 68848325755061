import React from "react"
import PropTypes from "prop-types"

import BodyText from "@components/common/BodyText"
import IconLinkButton from "@components/common/IconLinkButton"
import { ReactComponent as ChevronRight } from "@images/icons/chevron-right.svg"

import language from "@language"

import "./style.scss"

const LocationDepartmentStaffCard = props => {
  const medicalStaff = props.departmentLeader.medicalStaff || {}
  return (
    <div className="location-department-staff-card">
      <BodyText
        className="location-department-staff-card__name"
        text={`${medicalStaff.title}, ${medicalStaff.acf.title}`}
        color="primary"
        size="md2"
      />
      <BodyText
        className="location-department-staff-card__department"
        text={props.title}
        size="sm"
        color="dark-gray"
      />
      <BodyText
        className="location-department-staff-card__title"
        text={props.departmentLeader.leaderTitle}
        size="sm"
        color="dark-gray"
      />
      <IconLinkButton
        icon={<ChevronRight />}
        className="location-department-staff-card__cta"
        uri={medicalStaff.uri}
        text={language[props.language].view_their_profile}
      />
    </div>
  )
}

LocationDepartmentStaffCard.propTypes = {
  departmentLeader: PropTypes.shape({
    leaderTitle: PropTypes.string,
    medicalStaff: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
  }),
  language: PropTypes.string,
  title: PropTypes.string,
}
LocationDepartmentStaffCard.defaultProps = {
  language: "es",
}

export default LocationDepartmentStaffCard
